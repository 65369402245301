import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/analytics/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/logo512.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/layout/nav.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/libs/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter-sans\"}],\"variableName\":\"fontInter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/libs/fonts.ts\",\"import\":\"Roboto\",\"arguments\":[{\"display\":\"swap\",\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"variable\":\"--font-roboto-sans\"}],\"variableName\":\"fontRoboto\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/libs/fonts.ts\",\"import\":\"JetBrains_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-jetbrains-mono\"}],\"variableName\":\"fontMono\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Section.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/clients/Clients.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/community/Community.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/faq/Faq.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/roadmap/Roadmap.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/sections/services/Services.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/TelegramLink.tsx")